.App {
  text-align: center;
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {}

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.page {
  position: absolute;
  top: 140px;
  left: 50px;
  right: 50px;
}

.homepage-bgimage {
  background: url(./Images/background.jpeg);
  height: 100vh;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.loginpage {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex
}

.Stitchpage {
  top: 80px;
  left: 50px;
  right: 50px;
  overflow: hidden;
}

.rtable {
  margin: 4px ;
  /* background-color: black; */
}

.img-container {
  padding-left: 0rem;
  padding-right: 0rem;
  width: 100%;
  height: auto
}

/* .img-container img {
  padding-left: 0rem;
  padding-right: 0rem;
  width: 100%;
  height: auto
} */

.ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell,
.ag-header-group-cell {
  border-right: 1px solid lightgray;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #cccccc;
  border-radius: 6px;
  color: black;
  display: flex;
  line-height: 0px;
  /* font-weight: 300; */
  list-style: none;
  margin-bottom: 9px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.fa-ellipsis-h {
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: darkturquoise;
  border: 1px solid darkturquoise;
  padding: 8px;
}

.fa-spinner {
  background-color: white;
  border-radius: 50%;
  color: orange;
  border: 1px solid orange;
  padding: 8px;
}

.fa-check-square-o {
  background-color: white;
  border-radius: 50%;
  color: yellowgreen;
  border: 1px solid yellowgreen;
  padding: 8px;
}

.fa-exclamation-triangle {
  background-color: white;
  border-radius: 50%;
  color: red;
  border: 1px solid red;
  padding: 8px;
}

.fa-upload {
  background-color: white;
  border-radius: 50%;
  color: #e77a7a;
  border: 1px solid #e77a7a;
  padding: 8px;
}

.fa-trash {
  background-color: white;
  color: #7e91bb;
  border-radius: 50%;
  border: 1px solid #7e91bb;
  padding: 9px;
}

.Login form {
  margin: 0 auto;
  max-width: 400px;
}

/* .list-group{
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
} */

.annotationListItems .dropdown-menu {
  max-height: 200px;
  overflow: scroll;
}

.btn-group-vertical>button {
  margin-bottom: 10px;
}

.booth {
  background: #ccc;
  margin: 0 auto;
}

#rotateMode {
  border-radius: 50%;
  width: 118px;
  height: 118px;
  background-color: lightgray;
  color: white;
  /* position: absolute; */
  /* USE FLEXBOX */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#rotateMode1 {
  border-radius: 50%;
  width: 118px;
  height: 118px;
  background-color: lightgray;
  color: white;
  /* position: absolute; */
  /* USE FLEXBOX */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top,
.middle,
.bottom {
  display: flex;
  flex-direction: row;
}

.top,
.bottom {
  justify-content: center;
}

.middle {
  justify-content: space-between;
}


.fa-arrow-circle-left,
.fa-arrow-circle-right,
.fa-arrow-circle-up,
.fa-arrow-circle-down {
  color: green;
  background-color: lightgray;

}

.docs-data>.input-group {
  margin-bottom: 0.5rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.docs-data>.input-group>label {
  justify-content: center;
  height: 31px;
  width: 5px;
}

.docs-data>.input-group>span {
  justify-content: center;
}

.map-container {
  height: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  position: fixed;
  /* border: 1px solid blue; */
  /* left: 10%; */
}

div.ol-custom-overviewmap,
div.ol-custom-overviewmap.ol-uncollapsible {
  bottom: auto;
  left: 8px;
  /* right: 8px; */
  top: 325px;
}

div.ol-custom-overviewmap:not(.ol-collapsed) {
  border: 1px solid black;
}

div.ol-custom-overviewmap div.ol-overviewmap-map {
  /* border: none;
  width: 300px; */
  border: none;
  width: 400px;
  height: 210px;
  margin: 0px;
  padding: 0px;
  position: static;
  background: #333333;
}

div.ol-custom-overviewmap div.ol-overviewmap-box {
  /* border: 2px solid red; */
  border: 2px solid red;
  margin: 0px;
  padding: 0px;
  top: 0px;
  overflow: hidden;
  display: block;
  width: 350px;
  /* height: 395px; */
  height: 300px;
  position: relative;
  z-index: 999999999;
  cursor: default;
}

div.ol-custom-overviewmap:not(.ol-collapsed) button {
  bottom: auto;
  left: auto;
  /* right: 7px;
  top: 42px; */
  right: -22px;
  top: 0px;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-scale-line {
  bottom: 170px !important;
}

.mh .dropdown-menu {
  max-height: 100px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.videoTextArrowElement{
  font-size: 32px;
  color:black;
}

.videoTextElement {
  color:aquamarine;
  font-size: 12px;
  background-color: black;
}

.videoContainer{
  display: flex;
  justify-content: flex-end;
}

.camera-switch-btn{
  width: 25px;
  height: 25px;
  cursor: pointer;
}


#canvas{
  /* display: none; */
  /* visibility: hidden; */
  /* background-color: #e77a7a; */
  position: relative;
  height: 100%;
  z-index: 100;
  opacity: 0.5;
  cursor: crosshair;
}

.json-container {
  position: relative;
  min-width: 600px;
  max-height: 700px;
  overflow-y: auto;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.file-name {
  color: blue;
  cursor: pointer;
}

.config-table {
  max-height: 600px; 
  overflow-y: auto;
  max-width: 500px;
  margin-right: 20px;
}
